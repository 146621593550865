import { Champion2, LinkChampionPayload2 } from 'src/app/internal-app/details/champion/models/Champion';
import { ActivityActionType } from '../constants/Activity';
import { CommentType, EntityStatus, ENTITY_TYPE, ProjectType, Watchlist } from '../constants/CompanyOrFirm';
import { GROUP_STATUS } from '../constants/Groups/Group';
import { UserPermissionTab } from '../constants/Tabs';
import { VettedStatus } from '../constants/User';
import { RegisterRequest } from '../models/Auth';
import { Question } from '../models/Deal';
import { BaseFilters, Filter, FilterOption, IPageFilters, PageFilters } from '../models/filters/Filters';
import { AllGroupsFilters, MyGroupsFilters } from '../models/filters/GroupFilters';
import {
  ArchivedUserFilters,
  IncompleteRegistrationUserFilters,
  PendingUserFilters,
  UserFilters,
  WaitingRoomUserFilters,
} from '../models/filters/UserFilters';
import { CONTROL_STATE } from '../models/FormControls';
import { ArrayObjects, UUIDName } from '../models/GenericObject';
import { Member } from '../models/Group';
import { HelpVideos } from '../models/help-videos/HelpVideos';
import { HelpVideoSetupDto } from '../models/help-videos/HelpVideoSetup';
import { BudgetItem, FinancialStatement } from '../models/organisation/Organization';
import { AdminOrganizationProjectCard } from '../models/project/Project';
import { ProjectUpdate } from '../models/ProjectUpdate';
import { ChangeInvitationVetted, ChangePermissionRequest, GetUsersRequest } from '../models/UsersPermission';
import Utils from './Utils';

export default class ComputePayloadHelper {
  public static registerRequest(registerForm): RegisterRequest {
    return {
      email: registerForm.email.trim(),
      firstName: registerForm.firstName.trim(),
      lastName: registerForm.lastName.trim(),
      password: registerForm.passwords.password,
      token: registerForm.recaptcha,
    };
  }

  public static changePassword(passwordForm) {
    return {
      oldPassword: passwordForm.oldPassword,
      newPassword: passwordForm.passwords.password,
    };
  }

  public static filteredCatalogItems(filters: PageFilters<IPageFilters>, type: ENTITY_TYPE, languageCode) {
    const payload: any = {
      type,
      languageCode,
      dealType: type,
      page: filters.page,
      size: filters.size,
      searchText: filters.searchText,
      sortBy: filters.extractSortOptions(),
    };
    switch (type) {
      case ENTITY_TYPE.PROJECT:
      case ENTITY_TYPE.ORGANISATION:
      case ENTITY_TYPE.PORTFOLIO:
        payload.categories = filters.filters.categories.extractValue();
        payload.countries = filters.filters.countries.extractValue();
        payload.regions = filters.filters.regions.extractValue();
        payload.causes = filters.filters.causes.extractValue();
        payload.champions = filters.filters.champions.extractValue();
        break;
      default:
        break;
    }

    payload.firstRequest = this.checkIfFilterSelected(payload, filters.filters);
    return payload;
  }

  public static filteredPortfolioEntityItems(filters: PageFilters<IPageFilters>, type: ENTITY_TYPE, languageCode) {
    const payload: any = {
      type,
      dealType: type,
      languageCode,
      page: filters.page,
      size: filters.size,
      searchText: filters.searchText,
      sortBy: filters.extractSortOptions(),
      categories: filters.filters.categories.extractValue(),
      countries: filters.filters.countries.extractValue(),
      regions: filters.filters.regions.extractValue(),
      causes: filters.filters.causes.extractValue(),
    };

    payload.firstRequest = this.checkIfFilterSelected(payload, filters.filters);
    return payload;
  }

  public static filteredUsers(
    pageFilters: PageFilters<UserFilters | IncompleteRegistrationUserFilters | WaitingRoomUserFilters>,
    tab: UserPermissionTab
  ): GetUsersRequest {
    const payload = {
      days: pageFilters.filters.interval.extractValue()[pageFilters.filters.interval.extractValue().length - 1],
      pending: false,
      page: pageFilters.page,
      size: pageFilters.size,
      userTypes: tab === UserPermissionTab.INCOMPLETE_REGISTRATIONS ? null : pageFilters.filters.types.extractValue(),
      roles: pageFilters.filters.roles.extractValue(),
      vetted: this.extractBoolean(pageFilters.filters.vetted.options, VettedStatus.VETTED, VettedStatus.NON_VETTED),
      accreditedInvestorsDisclosure: false,
      searchText: pageFilters.searchText,
      sortBy: pageFilters.extractSortOptions(),
      sortByInvitedUser: null,
      lastModifiedDays: pageFilters.filters.lastModifiedDays.extractValue()[pageFilters.filters.lastModifiedDays.extractValue().length - 1],
      waitingRoom:
        tab === UserPermissionTab.WAITING_ROOM ? (pageFilters as PageFilters<WaitingRoomUserFilters>).filters.waitingRoom.extractValue() : false,
      completedRegistration:
        tab === UserPermissionTab.INCOMPLETE_REGISTRATIONS
          ? (pageFilters as PageFilters<IncompleteRegistrationUserFilters>).filters.completedRegistration.extractValue()
          : true,
      archive: tab === UserPermissionTab.ARCHIVED ? (pageFilters as PageFilters<ArchivedUserFilters>).filters.archived.extractValue() : false,
      organisationUuid: pageFilters.filters.searchByOrganisation.extractValue()?.length
        ? pageFilters.filters.searchByOrganisation.extractValue()[0]
        : null,
    };
    Object.assign(payload, { userStatus: pageFilters.filters.statuses.extractValue() });
    return payload;
  }

  public static filteredInvitedUsers(
    pageFilters: PageFilters<UserFilters | WaitingRoomUserFilters | IncompleteRegistrationUserFilters | PendingUserFilters>
  ): GetUsersRequest {
    const filters = pageFilters as PageFilters<PendingUserFilters>;
    const payload = {
      days: null,
      pending: true,
      page: filters.page,
      size: filters.size,
      userTypes: [],
      roles: [],
      vetted: null,
      accreditedInvestorsDisclosure: false,
      searchText: filters.searchText,
      sortByInvitedUser: filters.extractSortOptions(),
      organisationUuid: pageFilters.filters.searchByOrganisation.extractValue()?.length
        ? pageFilters.filters.searchByOrganisation.extractValue()[0]
        : null,
      waitingRoom: false,
      sortBy: null,
    };
    return payload;
  }

  public static createDealAdminUpdate(payload: any, assetUuid: string, submit: boolean) {
    return {
      assetUuid,
      headline: payload.headline,
      amountRemainingToBeRaised: payload.amountRemainingToBeRaised,
      customers: payload.customers,
      customersImage: payload.customersImage,
      productService: payload.productService,
      productServiceImage: payload.productServiceImage,
      funding: payload.funding,
      fundingImage: payload.fundingImage,
      teamImpact: payload.teamImpact,
      teamImpactImage: payload.teamImpactImage,
      published: submit,
      // ask: payload.ask
    };
  }

  public static changePermissions(uuid: string, permissionsData, organizationName?: string): ChangePermissionRequest {
    return {
      userUuid: uuid,
      userType: permissionsData.userType,
      entityUuid: permissionsData.entity?.uuid,
      organizationName: organizationName,
      newRole: permissionsData.role,
      champion: permissionsData.champion,
      userVetted: permissionsData.vetted,
      accreditedInvestor: permissionsData.accreditedInvestor,
      waitingRoom: permissionsData.waitingRoom,
      languageEditor: permissionsData.languageAdmin,
      rankingScore: permissionsData.rankingScore,
    };
  }

  public static changeInvitationVetted(permissionsData): ChangeInvitationVetted {
    return {
      vetted: permissionsData.vetted,
      tokenUuid: permissionsData.tokenUuid,
    };
  }

  public static removeElement(array: Array<any>): Array<any> {
    if (array?.length) {
      return array.filter((activityFilter) => activityFilter !== 'ALL');
    }
    return [];
  }

  private static checkIfFilterSelected(payload, filters: IPageFilters): boolean {
    let filterSelected = true;
    for (const key in filters) {
      if (filters[key] instanceof Filter) {
        filterSelected = filterSelected && Utils.compareArraysLength(payload[key], filters[key].options);
      }
    }
    return filterSelected;
  }

  private static extractBoolean(array: FilterOption[], trueValue: any, falseValue: any): boolean {
    let returnValue: boolean = null;
    for (const filter of array) {
      if (filter.checked && filter.value === trueValue) {
        returnValue = true;
        break;
      }
      if (filter.checked && filter.value === falseValue) {
        returnValue = false;
        break;
      }
    }
    return returnValue;
  }

  public static extractFilledItems(array: any[]): any[] {
    return array?.length ? array.filter((item) => !Utils.checkProperties(item)) : null;
  }

  public static uploadAnswerToQuestion(formData: any, questionInfo: Question) {
    return {
      entityUuid: questionInfo.assetUuid,
      questionUuid: questionInfo.uuid,
      commentType: CommentType.ANSWER,
      content: formData.answer,
    };
  }

  public static uploadQuestionToEntity(formData: any, assetUuid: string) {
    return {
      anonymous: formData.anonymous,
      content: formData.question,
      entityUuid: assetUuid,
      commentType: CommentType.QUESTION,
    };
  }

  public static toggleDealVisibility(makeLiveData): any {
    return typeof makeLiveData === 'object'
      ? {
          visible: !makeLiveData || makeLiveData?.scheduledToBePublished === 0 || makeLiveData?.scheduledToBePublished === 1,
          scheduled: makeLiveData?.scheduledToBePublished === 2 || makeLiveData?.scheduledToBePublished === 0,
          time: Utils.localToUTCNoOffset(makeLiveData?.timeToBePublished) || null,
        }
      : {
          visible: false,
          scheduled: false,
          time: null,
        };
  }

  public static toggleProjectVisibility(newStatus: EntityStatus, statusChangeData: any, uuid: string): any {
    return {
      uuid,
      entityStatus: newStatus,
      reason: typeof statusChangeData === 'string' ? statusChangeData : null,
      ...this.toggleDealVisibility(statusChangeData),
    };
  }

  public static toggleWatchList(uuid: string, addedToWatchlist: boolean, projectType: ProjectType) {
    return {
      entityUUID: uuid,
      watchlistType: addedToWatchlist ? Watchlist.REMOVE : Watchlist.ADD,
      fundType: projectType,
    };
  }

  public static resendInvitationPayload(token: string) {
    return {
      tokenUuid: token,
    };
  }

  public static deleteInvitationPayload(email: string) {
    return {
      emails: [email],
    };
  }

  public static activity(activityFilter: ActivityActionType[]): ActivityActionType[] {
    if (
      !activityFilter?.length ||
      (activityFilter?.length === 1 && activityFilter.indexOf(ActivityActionType.ALL) === 0) ||
      activityFilter?.length === 4
    ) {
      return [ActivityActionType.ALL];
    } else {
      return this.removeElement(activityFilter);
    }
  }

  public static extractFinancialStatements(data: any[]) {
    const payload: FinancialStatement[] = [];
    data.forEach((statement) => {
      const financialStatement: FinancialStatement = new FinancialStatement();
      financialStatement.budgetYearUUID = statement.budgetYear?.uuid;
      financialStatement.fiscalYearEnd = statement.fiscalYearEnd;
      financialStatement.totalActualsExpenses = statement.totalExpenseActuals;
      financialStatement.totalActualsRevenues = statement.totalRevenueBudget;
      financialStatement.totalBudgetExpenses = statement.totalOperatingBudget;
      financialStatement.totalBudgetRevenues = statement.totalRevenueBudget;
      financialStatement.netGainLossBudget = statement.netGainLossBudget;
      financialStatement.netGainLossActuals = statement.netGainLossActuals;
      financialStatement.uuid = statement.uuid;
      financialStatement.budgetCurrency = new UUIDName<string>(statement.statementCurrency.uuid, statement.statementCurrency.name);
      financialStatement.budgetYear = new UUIDName<string>(statement.budgetYear?.uuid, statement.budgetYear?.name);
      financialStatement.position = statement.position;
      statement.revenue.forEach((revenue) => {
        const budgetItem: BudgetItem = new BudgetItem();
        budgetItem.uuid = revenue.uuid;
        budgetItem.budget = revenue.revenueItemBudget;
        budgetItem.actuals = revenue.revenueItemActual;
        budgetItem.itemName = revenue.revenueItemName;
        budgetItem.position = revenue.position;
        financialStatement.budgetRevenues.push(budgetItem);
      });
      statement.expense.forEach((expense) => {
        const budgetItem: BudgetItem = new BudgetItem();
        budgetItem.uuid = expense.uuid;
        budgetItem.budget = expense.expenseItemBudget;
        budgetItem.actuals = expense.expenseItemActual;
        budgetItem.itemName = expense.expenseItemName;
        budgetItem.position = expense.position;
        financialStatement.budgetExpenses.push(budgetItem);
      });
      payload.push(financialStatement);
    });
    return payload;
  }

  public static createUpdateOrganization(data: any, submitted: boolean, modifiedFields: ArrayObjects, organisationUuid, language) {
    return {
      organisationUuid,
      mandatoryProfileUuid: data.primaryInformation.organizationProfile.uuid,
      organisationName: data.primaryInformation.organizationName,
      isPrivate: data.primaryInformation.isPrivate,
      address: data.primaryInformation.address,
      organisationWebsite: data.primaryInformation.website,
      socialMediaLinkedin: data.primaryInformation.socialMedia.linkedin,
      socialMediaTwitter: data.primaryInformation.socialMedia.twitter,
      socialMediaFacebook: data.primaryInformation.socialMedia.facebook,
      socialMediaInstagram: data.primaryInformation.socialMedia.instagram,
      cause: data.primaryInformation.cause,
      organisationCategories: data.primaryInformation.category,
      targetPopulation: data.primaryInformation.targetPopulation,
      organisationInfluencedCountries: data.primaryInformation.influencedCountries,
      yearFounded: data.primaryInformation.yearFounded,
      givingLink: data.primaryInformation.givingLink,
      organisationVideo: data.detailsInformation.organizationVideo,
      organisationImages: this.extractValidImagesToUpload(data.detailsInformation.organizationImages),
      executiveSummary: data.detailsInformation.executiveSummary,
      visionStatement: data.detailsInformation.visionStatement,
      missionStatement: data.detailsInformation.missionStatement,
      organisationValues: data.detailsInformation.organisationValues,
      programAndActivities: data.detailsInformation.programAndActivities,
      history: data.detailsInformation.history,
      organisationLeader: data.teamInformation.organisationLeader,
      modifiedFields,
      numberOfEmployees: data.teamInformation.numberOfEmployees,
      numberOfVolunteers: data.teamInformation.numberOfVolunteers,
      executiveTeams: this.extractFilledItems(data.teamInformation.executiveTeam),
      boardMembers: this.extractFilledItems(data.teamInformation.boardMembers),
      partnerOrganisations: this.extractFilledItems(data.teamInformation.partnerOrganizations),
      budgetOrganisations: this.extractFinancialStatements(data.financialInformation.budgetAndFinancialStatements),
      championCommitmentAmount: data.championInformation?.championCommitmentAmount,
      championCommitmentCurrencyUuid: data.championInformation?.championCommitmentCurrency,
      championAmountAlreadyInvested: data.championInformation?.championPreviousInvestmentAmount,
      championAmountAlreadyInvestedCurrencyUuid: data.championInformation?.championPreviousInvestmentCurrency,
      championHistory: data.championInformation?.championHistoryDiligence,
      championImpactStory: data.championInformation?.championImpactStory,
      championQuote: data.championInformation?.championQuote,
      championTopRisks: data.championInformation?.championTopRisks,
      championUuid: data.championInformation?.champion,
      submitted,
      languageUuid: language,
      organisationDocuments: data.documentsInformation.organisationDocuments,
    };
  }

  public static createGetDealUpdatesPayload(dealType, page: number, entityUuid, draftNeeded: boolean = false) {
    return {
      dealType,
      entityUuid,
      page,
      size: 5,
      sortBy: {
        field: 'MODIFICATION_DATE',
        order: 'Desc',
      },
      draftNeeded,
    };
  }

  public static filteredOrganizations(pageFilters: PageFilters<IPageFilters>, organisationStatus: EntityStatus) {
    return {
      firstRequest: true,
      page: pageFilters.page,
      size: pageFilters.size,
      searchText: pageFilters.searchText,
      sortBy: pageFilters.extractSortOptions(),
      statusList: organisationStatus ? [organisationStatus] : [],
      projectStatusList: pageFilters.filters.projectsStatuses.extractValue(),
    };
  }

  public static filteredProjects(pageFilters: PageFilters<IPageFilters>, projectStatus: EntityStatus, languageCode: string) {
    const projectStatuses = [...pageFilters.filters.projectsStatuses.extractValue(), projectStatus];
    return {
      firstRequest: false,
      page: pageFilters.page,
      size: pageFilters.size,
      searchText: pageFilters.searchText,
      sortBy: pageFilters.extractSortOptions(),
      projectStatusList: [...new Set(projectStatuses)],
      type: 'PROJECT',
      dealType: 'PROJECT',
      languageCode,
    };
  }

  public static filteredPortfolios(pageFilters: PageFilters<IPageFilters>) {
    let statusList: string[] = pageFilters.filters.statusList.extractValue().length
      ? pageFilters.filters.statusList.extractValue()
      : pageFilters.filters.statusList.options.map((item) => item.code);
    if (statusList.includes(EntityStatus.ARCHIVED) || statusList.includes(EntityStatus.DECLINED)) {
      statusList = statusList.filter((status) => status !== EntityStatus.ARCHIVED && status !== EntityStatus.DECLINED);
    }
    const portfolioTypeList = pageFilters.filters.portfolioType.extractValue().length
      ? pageFilters.filters.portfolioType.extractValue()
      : pageFilters.filters.portfolioType.options.map((item) => item.code);
    return {
      order: pageFilters.extractSortOptions().order,
      page: pageFilters.page,
      size: pageFilters.size,
      searchText: pageFilters.searchText,
      sortBy: pageFilters.extractSortOptions().field,
      statusList: statusList,
      portfolioTypeList,
    };
  }

  public static createUpdateProject(form, organisationUuid, projectUuid, submitted: boolean, modifiedFields, language) {
    return {
      activities: form.details.activities,
      amountRaisedToDate: form.primaryInformation.fundsInfo.amountRaisedToDate,
      amountRaisedToDateCurrency: form.primaryInformation.fundsInfo.projectCurrency,
      cause: form.primaryInformation.projectCause,
      description: form.details.description,
      endDate: Utils.localToUTCNoOffset(form.primaryInformation.projectLifetime.endDate),
      financialSustainability: form.goal.financialSustainability,
      givingLink: form.primaryInformation.givingLink,
      goals: this.extractFilledItems(form.goal.listOfGoals),
      immediateResults: form.goal.immediateResults,
      languageUuid: language,
      longTermImpact: form.goal.longTermImpact,
      mandatoryProfileUuid: form.primaryInformation.projectProfile.uuid,
      modifiedFields,
      organisationUuid,
      otherSocialMediaLink: form.primaryInformation.projectSocialMediaLinks.projectOtherLink,
      partnerOrganisations: this.extractFilledItems(form.team.partnerOrganisations),
      potentialForGrowth: form.goal.potentialForGrowth,
      problem: form.details.problem,
      projectCategories: form.primaryInformation.projectCategory,
      projectCountries: form.primaryInformation.projectCountry,
      projectDocuments: form.documentsInformation.projectDocuments,
      projectImages: this.extractValidImagesToUpload(form.details.projectImages),
      projectManagersUUID: form.team.projectManager?.uuid || null,
      projectName: form.primaryInformation.projectName,
      projectNumber: form.primaryInformation.projectNumber,
      projectTeamMediaPicture: form.team.teamMedia.projectTeamMediaImage,
      projectTeamMediaVideo: form.team.teamMedia.projectTeamMediaVideo,
      projectTeamMediaThumbnailVideo: form.team.teamMedia.projectTeamMediaVideoThumbnail,
      projectUuid,
      projectVideo: form.details.projectVideo,
      resultsMeasurement: form.goal.resultsMeasurement,
      shortTermOutcomes: form.goal.shortTermOutcomes,
      socialMediaFacebook: form.primaryInformation.projectSocialMediaLinks.projectFacebook,
      socialMediaInstagram: form.primaryInformation.projectSocialMediaLinks.projectInstagram,
      socialMediaLinkedin: form.primaryInformation.projectSocialMediaLinks.projectLinkedin,
      socialMediaTwitter: form.primaryInformation.projectSocialMediaLinks.projectTwitter,
      solution: form.details.solution,
      startDate: Utils.localToUTCNoOffset(form.primaryInformation.projectLifetime.startDate),
      submitted,
      targetPopulation: form.primaryInformation.targetPopulation,
      teamMembers: this.extractFilledItems(form.team.projectTeamMembers),
      teamSummary: form.team.teamSummary,
      totalProjectBudget: form.primaryInformation.fundsInfo.totalProjectBudget,
      totalProjectBudgetCurrency: form.primaryInformation.fundsInfo.projectCurrency,
      website: form.primaryInformation.projectWebsite,
    };
  }

  public static createUpdateGroup(form, groupUuid) {
    return {
      groupUUID: form.uuid || groupUuid,
      name: form.groupName,
      description: form.groupDescription,
      cause: form.cause,
      categories: form.category,
      regions: form.region,
      countries: form.country,
      unlisted: !form.visibility,
      adminInviteOnly: form.permissions.permissionInviteOnly,
      adminApprovalNeeded: form.permissions.permissionApprovalNeeded,
      projects: form.projects,
      organisations: form.organisations,
      portfolios: form.portfolios,
      visionVideo: form.groupMediaVideo,
      visionThumbnailVideo: form.groupMediaVideoThumbnail,
      visionImage: form.groupMediaImage,
    };
  }

  public static updateProjectScore(project: AdminOrganizationProjectCard) {
    return {
      score: project.score,
      uuid: project.uuid,
    };
  }

  public static createPublishUpdate(projectUpdate: ProjectUpdate, projectUuid, published) {
    return {
      headline: projectUpdate.headline,
      projectUuid,
      published,
      amountRaisedToDate: projectUpdate.amountRaisedToDate,
      update: projectUpdate.update,
    };
  }

  public static submitCommitToGive(data: any, entityType: string, entityUuid: string) {
    return {
      donationAmount: data.donationAmount,
      currencySymbol: data.currency,
      transactionProcessingOption: data.transactionProcessing,
      sharingConfirmation: data.sharingConfirmation,
      fullName: data.fullName,
      email: data.email,
      entityType,
      entityUuid,
      optionalMessageToOrganisation: data.optionalMessage,
      commitToFulfilPledge: data.commitment,
    };
  }

  public static getAllGroupsPayload(pageFilters: PageFilters<AllGroupsFilters>) {
    return {
      categories: pageFilters.filters.categories.extractValue(),
      causes: pageFilters.filters.causes.extractValue(),
      countries: pageFilters.filters.countries.extractValue(),
      regions: pageFilters.filters.regions.extractValue(),
      memberState: ['JOINED', 'NOT_JOINED', 'DECLINED', 'PENDING_INVITATION', 'REQUESTED_TO_JOIN'],
      groupStatus: pageFilters.filters.status.extractValue(),
      page: pageFilters.page,
      size: pageFilters.size,
      searchText: pageFilters.searchText,
      sortBy: pageFilters.extractSortOptions(),
    };
  }

  public static getMyGroupsPayload(pageFilters: PageFilters<MyGroupsFilters>, ownedGroups: boolean) {
    return {
      admin: ownedGroups,
      firstRequest: true,
      page: pageFilters.page,
      size: pageFilters.size,
      searchText: pageFilters.searchText,
      sortBy: pageFilters.extractSortOptions(),
      groupsStatus: [GROUP_STATUS.PENDING, GROUP_STATUS.APPROVED, GROUP_STATUS.ARCHIVED, GROUP_STATUS.DECLINED],
      memberState: pageFilters.filters.groupsType.extractValue(),
    };
  }

  public static getUpdateGroupEntitiesPayload(data: any, entityType: ENTITY_TYPE) {
    let projectUuids = [];
    let organisationUuids = [];
    let portfolioUuids = [];
    switch (entityType) {
      case ENTITY_TYPE.PROJECT:
        data.projects.forEach((project) => (projectUuids = [...projectUuids, project.uuid]));
        break;
      case ENTITY_TYPE.ORGANISATION:
        data.organisations.forEach((organisation) => (organisationUuids = [...organisationUuids, organisation.uuid]));
        break;
      case ENTITY_TYPE.PORTFOLIO:
        data.portfolios.forEach((portfolio) => (portfolioUuids = [...portfolioUuids, portfolio.uuid]));
        break;
    }

    return {
      groupUuid: data.groupUuid,
      projectUuids: entityType === ENTITY_TYPE.PROJECT ? projectUuids : null,
      organisationUuids: entityType === ENTITY_TYPE.ORGANISATION ? organisationUuids : null,
      portfolioUuids: entityType === ENTITY_TYPE.PORTFOLIO ? portfolioUuids : null,
    };
  }

  public static getDeleteGroupEntityPayload(data: any, entityType: ENTITY_TYPE) {
    let projectUuids = [];
    let organisationUuids = [];
    let portfolioUuids = [];
    switch (entityType) {
      case ENTITY_TYPE.PROJECT:
        data.entities.forEach((project) => {
          if (project.uuid !== data.entityUuid) {
            projectUuids = [...projectUuids, project.uuid];
          }
        });
        break;
      case ENTITY_TYPE.ORGANISATION:
        data.entities.forEach((organisation) => {
          if (organisation.uuid !== data.entityUuid) {
            organisationUuids = [...organisationUuids, organisation.uuid];
          }
        });
        break;
      case ENTITY_TYPE.PORTFOLIO:
        data.entities.forEach((portfolio) => {
          if (portfolio.uuid !== data.entityUuid) {
            portfolioUuids = [...portfolioUuids, portfolio.uuid];
          }
        });
        break;
    }

    return {
      groupUuid: data.groupUuid,
      projectUuids: entityType === ENTITY_TYPE.PROJECT ? projectUuids : null,
      organisationUuids: entityType === ENTITY_TYPE.ORGANISATION ? organisationUuids : null,
      portfolioUuids: entityType === ENTITY_TYPE.PORTFOLIO ? portfolioUuids : null,
    };
  }

  public static getGroupEntitiesPayload(groupUuid: string, filterMenu: BaseFilters, entityType: ENTITY_TYPE) {
    return {
      firstRequest: true,
      groupUuid: groupUuid,
      entityType: entityType,
      page: filterMenu.page,
      size: filterMenu.size,
      searchText: filterMenu.searchText,
      sortByEntities: filterMenu.extractSortOptions(),
      categories: [],
      marketSectors: [],
      regions: [],
      types: [],
    };
  }

  public static getDocumentsPayload(filterMenu: BaseFilters, groupUuid: string) {
    return {
      page: filterMenu.page,
      searchText: filterMenu.searchText,
      size: filterMenu.size,
      uuid: groupUuid,
    };
  }

  public static getCreateUpdateAnnouncementPayload(data: any, groupUuid: string, documentsList: any[]) {
    let documents: any[] = [];
    documentsList.forEach((document: any) => documents.push(document.uuid));
    return {
      announcement: data.announcement,
      existingDocuments: documents,
      groupUuid: groupUuid,
      title: data.title,
      uuid: data.uuid ? data.uuid : null,
    };
  }

  public static getAllGroupAnnouncementsPayload(groupUuid: string, filterMenu: BaseFilters) {
    return {
      page: filterMenu.page,
      size: filterMenu.size,
      searchText: filterMenu.searchText,
      uuid: groupUuid,
    };
  }

  public static addGroupMembersPayload(groupUuid: string, members: any[]) {
    let body: any[] = [];
    this.extractFilledItems(members).forEach((invitation) => {
      const invite = {
        email: invitation.email,
        firstName: invitation.firstName,
        lastName: invitation.lastName,
        groupUuid: groupUuid,
      };
      body.push(invite);
    });
    return body;
  }

  public static handleParticipantPayload(action: string, groupUuid: string, member?: Member) {
    if (member?.tokenUuid) {
      return {
        groupAction: action,
        groupUuid: groupUuid,
        tokenUuid: member.tokenUuid,
      };
    } else {
      return {
        groupAction: action,
        groupUuid: groupUuid,
        userUuid: member?.uuid || '',
      };
    }
  }

  public static getGroupMembersPayload(statusFilter: string[], filterMenu, groupUuid) {
    return {
      statusFilter: statusFilter,
      userName: filterMenu.searchText,
      uuid: groupUuid,
    };
  }

  private static extractUuidForProfile(item): any {
    if (item.value !== undefined) {
      switch (item.value) {
        case CONTROL_STATE.OPTIONAL:
          return [];
        case CONTROL_STATE.HIDDEN:
          return [{ uuid: item.uuid, hidden: true, name: item.name }];
        case CONTROL_STATE.MANDATORY:
          return [{ uuid: item.uuid, hidden: false, name: item.name }];
      }
    } else {
      const fields = [];
      Object.values(item).forEach((val) => fields.push(...this.extractUuidForProfile(val)));
      return fields;
    }
  }

  public static getCreateUpdateProfilePayload(form, name, uuid, profileType) {
    return {
      uuid,
      entityName: profileType,
      name,
      fields: [...this.extractUuidForProfile(form)],
    };
  }

  public static createUpdateLanguage(language, status, data) {
    return {
      countryName: language?.country || null,
      json: data ? JSON.stringify(data) : null,
      languageCode: language?.languageCode || null,
      state: status,
      uuid: language?.uuid || null,
    };
  }

  public static createUpdateRegion(form) {
    return {
      uuid: form.uuid || null,
      name: form.name,
      active: form.active,
      countries: form.countries ? form?.countries?.map((country) => country.uuid) : [],
    };
  }

  public static createUpdateCause(form) {
    return {
      uuid: form.uuid || null,
      name: form.name,
    };
  }

  public static createUpdateCategory(form) {
    return {
      uuid: form.uuid || null,
      name: form.name,
    };
  }

  public static createUpdateHelpVideo(currentHelpVideos: HelpVideos[], form: any, isUpdate: boolean = false): HelpVideoSetupDto[] {
    let helpVideos: HelpVideoSetupDto[] = [];
    helpVideos = currentHelpVideos.map((hv) => {
      const helpVideo: HelpVideoSetupDto = {
        description: hv.description,
        title: hv.title,
        url: hv.url,
      };

      if (!!hv.uuid) {
        helpVideo.uuid = hv.uuid;
      }

      return helpVideo;
    });

    if (!isUpdate) {
      const helpVideo: HelpVideoSetupDto = {
        description: form.description,
        title: form.title,
        url: form.url,
      };

      helpVideos.push(helpVideo);
    }
    return helpVideos;
  }

  public static extractValidImagesToUpload(images) {
    return images.filter((image) => image.mediaDto?.fileName && image.mediaDto?.url);
  }

  public static linkChampionDraftPayload(entityUuid: string, champion: Champion2, organisationChampionLinkUuid?: string): LinkChampionPayload2 {
    return {
      organisationUuid: entityUuid,
      organisationChampionLinkUuid,
      championLinkedEntityData: {
        engagementMonth: champion.organizationEngagement.engagementStartMonth ? +champion.organizationEngagement.engagementStartMonth.uuid : null,
        engagementYear: champion.organizationEngagement.engagementStartYear ? +champion.organizationEngagement.engagementStartYear.uuid : null,
        photoOrVideo: {
          uuid:
            champion.championMedia.image || champion.championMedia.video
              ? champion.championMedia.image.uuid ?? champion.championMedia.video.uuid
              : null,
        },
        testimonial: champion.testimonial ?? null,
        uuid: null,
        validityMonth: champion.testimonialVisibility ? +champion.testimonialVisibility.uuid : null,
      },
    };
  }

  public static linkChampionPayload(entityUuid: string, champion: Champion2, organizationChampionLinkUuid?: string): LinkChampionPayload2 {
    return {
      organisationUuid: entityUuid,
      organisationChampionLinkUuid: organizationChampionLinkUuid,
      championLinkedEntityData: {
        engagementMonth: +champion.organizationEngagement.engagementStartMonth.uuid,
        engagementYear: +champion.organizationEngagement.engagementStartYear.uuid,
        photoOrVideo: {
          uuid: champion.championMedia.image.uuid ?? champion.championMedia.video.uuid,
        },
        testimonial: champion.testimonial,
        uuid: null,
        validityMonth: +champion.testimonialVisibility.uuid,
      },
    };
  }
}
