export enum EnvironmentName {
  TB = 'Trustbridge',
  EGCC = 'EGCC',
  SE = 'SaltExchange',
  MACLELLAN = 'Maclellan',
  SINNGEBER = 'Sinngeber',
  PCP = 'Pcp',
  ENVIRONMENTNAME = 'CONSTANT_ENVIRONMENTNAME',
}

export enum TenantId {
  TRUSTBRIDGE_DEMO = 'swiss_demo',
  TRUSTBRIDGE_DEV = 'swiss_dev',
  TRUSTBRIDGE_TEST = 'swiss_test  ',
  TRUSTBRIDGE_PROD = 'swiss_prod',
  EGCC_DEV = 'egcc',
  EGCC_TEST = 'egcc_test',
  EGCC_PROD = 'egcc_prod',
  SALTEXCHANGE_PROD = 'saltexchange_prod',
  SALTEXCHANGE_TEST = 'saltexchange_test',
  MACLELLAN_PROD = 'maclellan_prod',
  SINNGEBER_PROD = 'sinngeber',
  PCP_PROD = 'pcp_prod',
  PCP_TEST = 'pcp_test',
  TENANTID = 'CONSTANT_TENANTID',
}

export enum EnvironmentInstance {
  DEMO = 'demo',
  DEV = 'dev',
  TEST = 'test',
  PROD = 'prod',
  STAGING = 'staging',
  ENVIRONMENTINSTANCE = 'CONSTANT_ENVIRONMENTINSTANCE',
}
