import { Injectable } from '@angular/core';
import FormFactoryUtils from '../helpers/FormFactoryUtils';
import StorageHelper from '../helpers/Storage.helper';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { UploadPromiseResponse } from '../models/UploadResponse';
import { Subject } from 'rxjs';
import { ToastrMessages } from '../constants/ToastrMessages';
import { FileType } from '../constants/FileType';
import { AdminService } from '../api/admin.service';
import { GeneralService } from '../api/general.service';
import { AccountService } from '../api/account.service';
import Utils from '../helpers/Utils';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';
import { Control, Media } from '../models/FormControls';
import { FormControl } from '@angular/forms';
import { GSFormControl } from '../models/forms/GSFormControl';

@Injectable({ providedIn: 'root' })
export class UploadService {
  private readonly uploader: FileUploader;
  public readonly maxFileSize = 2 * 1024 * 1024 * 1024; // 2GB

  public uploaderObservable = new Subject<any>();

  // prettier-ignore
  constructor(
    private accountService: AccountService,
    private adminService: AdminService,
    private generalService: GeneralService,
    private toastService: ToastService
  ) {
    this.uploader = this.setAssetUploaderInstance();
    this.setUploaderMethods();
  }

  get uploaderInstance() {
    return this.uploader;
  }

  setAssetUploaderInstance(): FileUploader {
    return new FileUploader({
      // url: this.companyService.getFileUploadURL(),
      url: this.generalService.getUploadUrl(),
      authToken: `Bearer ${StorageHelper.getToken()}`,
      headers: [{ name: 'tenantId', value: environment.tenantId }],
      autoUpload: false,
      itemAlias: 'file',
      removeAfterUpload: false,
      maxFileSize: this.maxFileSize,
    });
  }

  updateToken(): void {
    this.uploader.setOptions({ url: this.generalService.getUploadUrl() });
    this.uploader.authToken = `Bearer ${StorageHelper.getToken()}`;
  }

  setUploaderMethods() {
    this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => this.emitResponse(fileItem, progress.toString(), true);
    this.uploader.onSuccessItem = (fileItem: FileItem, response: any) => this.emitResponse(fileItem, response, false);
    this.uploader.onErrorItem = (fileItem: FileItem, response: any) => this.handleError(fileItem, response);
    this.uploader.onBuildItemForm = (fileItem: FileItem, form) => this.computeBuildItem(fileItem, form);
    this.uploader.onWhenAddingFileFailed = (_, filter, __) => this.handleAddFileError(filter);
  }

  uploadFileV3(mediaControl: GSFormControl, entityUuid: string = '', secondUuid: string = '', b64string?) {
    this.updateToken();
    const media = mediaControl.value as Media;
    const file = this.uploader.queue[this.uploader.queue.length - 1];
    media.isFormatValid = Utils.checkFileType(file.file.name, file.file.type, media.allowedFileType);

    if (b64string) {
      file._file = Utils.convertB64ToImage(b64string, file);
    }

    if (!media.isFormatValid) {
      this.toastService.error(ToastrMessages.INVALID_FORMAT);
      file.remove();
      return;
    }

    if (file.file.size > this.maxFileSize) {
      this.toastService.error(ToastrMessages.FILE_SIZE);
      file.remove();
      return;
    }

    file.formData.mediaControl = mediaControl;
    file.formData.fileType = media.fileType;
    file.formData.secondUuid = secondUuid || '';
    file.formData.entityUuid = entityUuid || '';

    media.progress = 0;
    media.pendingUpload = true;
    this.computeFileNames();
    this.uploader.uploadAll();
  }

  uploadFileV2(mediaControl: FormControl, control: Control, entityUuidControl: FormControl, b64string: any = null) {
    this.updateToken();
    const media = mediaControl.value as Media;
    const file = this.uploader.queue[this.uploader.queue.length - 1];
    media.isFormatValid = Utils.checkFileType(file.file.name, file.file.type, media.allowedFileType);

    if (b64string) {
      file._file = Utils.convertB64ToImage(b64string, file);
    }

    if (!media.isFormatValid) {
      this.toastService.error(ToastrMessages.INVALID_FORMAT);
      file.remove();
      return;
    }

    if (file.file.size > this.maxFileSize) {
      this.toastService.error(ToastrMessages.FILE_SIZE);
      file.remove();
      return;
    }

    file.formData.mediaControl = mediaControl;
    file.formData.fileType = media.fileType;
    const secondUuid = FormFactoryUtils.getSecondUuidControl(control, mediaControl)?.value;
    file.formData.secondUuid = secondUuid?.uuid || secondUuid || '';
    file.formData.entityUuid = entityUuidControl.value || secondUuid?.uuid || secondUuid || '';
    file.formData.control = control;

    media.progress = 0;
    media.pendingUpload = true;
    this.computeFileNames();
    this.uploader.uploadAll();
  }

  private computeFileNames() {
    this.uploader.queue.forEach((file: FileItem) => (file.file.name = file.file.name.replace(/[^0-9a-zA-z._&\-\s]+/g, '').replace(/\ /g, '_')));
  }

  // duplicated because there is too much to refactor on backend
  uploadUserProfileImage(mediaControl: GSFormControl, userUuid: string = '', b64string?) {
    this.updateToken();
    const media = mediaControl.value as Media;
    const file = this.uploader.queue[this.uploader.queue.length - 1];
    media.isFormatValid = Utils.checkFileType(file.file.name, file.file.type, media.allowedFileType);

    if (b64string) {
      file._file = Utils.convertB64ToImage(b64string, file);
    }

    if (!media.isFormatValid) {
      this.toastService.error(ToastrMessages.INVALID_FORMAT);
      file.remove();
      return;
    }

    if (file.file.size > this.maxFileSize) {
      this.toastService.error(ToastrMessages.FILE_SIZE);
      file.remove();
      return;
    }

    file.formData.mediaControl = mediaControl;
    file.formData.fileType = media.fileType;
    file.formData.userUuid = userUuid;

    media.progress = 0;
    media.pendingUpload = true;

    userUuid
      ? this.uploader.setOptions({ url: this.adminService.getUploadImageUrl() })
      : this.uploader.setOptions({ url: this.accountService.getUploadProfileImageUrl() });
    this.uploader.authToken = `Bearer ${StorageHelper.getToken()}`;
    this.computeFileNames();
    this.uploader.uploadAll();
  }

  private computeBuildItem(fileItem: FileItem, form) {
    switch (fileItem.formData.fileType) {
      case FileType.PROFILE_IMAGE:
        form.append('fileType', fileItem.formData.fileType);
        fileItem.formData.userUuid ? form.append('userUuid', fileItem.formData.userUuid) : null;
        break;
      default:
        form.append('entityUuid ', fileItem.formData.entityUuid);
        form.append('fileType ', fileItem.formData.fileType);
        form.append('secondUuid ', fileItem.formData.secondUuid);
        break;
    }
    return { fileItem, form };
  }

  private emitResponse(fileItem: FileItem, response: string, progress: boolean) {
    const result: UploadPromiseResponse = new UploadPromiseResponse(fileItem, JSON.parse(response));
    this.uploaderObservable.next({ result, progress });
    if (!progress) {
      this.uploader.progress = 0;
      this.uploader.removeFromQueue(fileItem);
      this.uploader.isUploading = false;
    }
  }

  private handleError(fileItem: FileItem, response: string) {
    JSON.parse(response).error === 'invalid_token'
      ? this.toastService.error(ToastrMessages.EXPIRED_SESSION)
      : this.emitResponse(fileItem, response, false);
  }

  private handleAddFileError(filter: any): void {
    switch (filter.name) {
      case 'fileSize':
        this.toastService.error(ToastrMessages.ERROR_MAX_FILE_SIZE);
        break;
      default:
        break;
    }
  }
}
