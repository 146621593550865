import { EnvironmentInstance, EnvironmentName, TenantId } from 'src/app/_core/constants/EnvironmentConst';
import { Environment } from 'src/app/_core/models/IEnvironment';

export const environment: Environment = {
  name: EnvironmentName.TB,
  tenantId: TenantId.TRUSTBRIDGE_DEMO,
  environmentInstance: EnvironmentInstance.DEMO,
  defaultLanguage: 'en-tb',
  appVersion: require('../../package.json').version,
  production: false,
  url: 'https://demo.givingspace.com',
  server: 'https://demo.givingspace.com/api',
  authServer: 'https://demo.givingspace.com/api-auth',
  mattermostUrl: 'https://demo.mattermost.givingspace.com',
  secure: false,
  changeOrigin: true,
  placesApiKey: 'AIzaSyCDVRL0Igtki_L6-xHqyquPHdLZ3D3GpXA',
  recaptchaApiKey: '6Ld9tNYfAAAAAInURAhAM34V5OmNg74xWnH4Ib19',
  authBackground: 'assets/env-assets/tb/bg.jpg',
  whiteLogo: 'assets/env-assets/tb/logo-white.png',
  blackLogo: 'assets/env-assets/tb/logo-black-v2.png',
  favicon: 'assets/env-assets/tb/favicon.ico',
  termsOfUse: 'https://www.trustbridgeglobal.com/terms',
  privacyPolicy: 'https://www.trustbridgeglobal.com/privacy',
  emailAddress: 'admin@trustbridgeglobal.com',
  hasCause: true,
  hasCodeOfConduct: true,
  isEnglishDefault: true,
  unleashConfig: {
    clientKey: 'default:development.99f139b1d6ec362fdb9827e2379e7ffd136a75259799582616f86d8b',
    url: 'https://unleash.benevatech.com/api/frontend',
  },
};
