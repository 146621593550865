import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SharedModule } from './_shared/shared.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptorService } from './_core/interceptors/token-interceptor.service';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { AppInitializer } from './_core/services/app-initializer.service';
import { first } from 'rxjs/operators';
import { catchError, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnvironmentName } from './_core/constants/EnvironmentConst';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de'
import localeCs from '@angular/common/locales/cs'
import { NgxUnleashProxyClientModule } from '@snowfrog/ngx-unleash-proxy-client'

export function initializeApp(appInitializer: AppInitializer): () => Observable<any> {
  return () => {
    return appInitializer.initialize().pipe(catchError(() => of(null)), first());
  };
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    // Allow placeholders throughout application to have their value removed from language interface
    // and to return empty strings instead of their key
    const pathsForEmptyValues = ['.PLACEHOLDERS.', 'DROPDOWNS.CATEGORIES.'];
    if (pathsForEmptyValues.some(path => params.key.indexOf(path) > -1)) {
      return '';
    } else {
      return params.key;
    }
  }
}

const setLocaleId = () => {
  switch (environment.name) {
    case EnvironmentName.SINNGEBER:
      registerLocaleData(localeDe)
      return 'de-DE';
      case EnvironmentName.EGCC:
        registerLocaleData(localeCs)
        return 'cs-CZ';
        default:
      return 'en-US';
  }
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    QuillModule.forRoot(),
    TranslateModule.forRoot({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
    }),
    PopoverModule.forRoot(),
    RxReactiveFormsModule,
    NgxUnleashProxyClientModule.init({
      url: environment.unleashConfig.url,
      clientKey: environment.unleashConfig.clientKey,
      appName: environment.name,
      context: {
        properties: {
          tenantId: environment.tenantId
        }
      }
    }),
  ],
  providers: [
    AppInitializer,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializer],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: Window,
      useValue: window
    },
    {
      provide: LOCALE_ID,
      useValue: setLocaleId()
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-US'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
